import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import NormalLink from "../../NormalLink";

const cardData = [
  {
    img: "https://3dpdashboard.com/main/latest/images/3dpLogo.png",
    title: "3DP Dashboard",
    description:
      "Organize and track all your 3D printers, filament, and prints with handy dashboard metrics.",
    link: "https://3dpdashboard.com",
  },
  {
    img: `${process.env.PUBLIC_URL}/bookmark.png`,
    title: "Bookmarker",
    description:
      "A small site to track online bookmarks, with absolutely no user tracking of any kind.",
    link: "https://bookmarker.eforge.us",
  },
  {
    img: `${process.env.PUBLIC_URL}/weather.png`,
    title: "WeatherForge",
    description:
      "Simple weather app, no tracking, no ads, just the weather.",
    link: "https://weather.eforge.us",
  },
  {
    img: `${process.env.PUBLIC_URL}/averagejoes3.png`,
    title: "The Average Joes Band",
    description:
      "I was in a band! Well, several actually. But this was the most recent and most fun.",
    link: "https://averagejoesband.com",
  },
  {
    img: "https://fabcfbb5a7a4f3986549.cdn6.editmysite.com/uploads/b/fabcfbb5a7a4f3986549f5c93bdc70cc1842e22a78a25261fec989390a1eaa83/12.14.2023_657b443867a496.41385070.png?width=2400&optimize=medium",
    title: "Living Art Statuettes",
    description:
      "My wife and I's side business, 3D prints of famous sculptures paired with air plants for a unique piece of art for your home.",
    link: "https://livingartstatuettes.com",
  },
  {
    img: "https://d1e8npr9kdrnr5.cloudfront.net/main/latest/static/media/awake.7778267114ee8f785b01.jpg",
    title: "Pocketful of Toesies",
    description: 'Incredibally life-like hand crafted "reborn" baby dolls.',
    link: "https://pocketfuloftoesies.com",
  },
];

const SyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: 0,
  height: "100%",
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  "&:focus-visible": {
    outline: "3px solid",
    outlineColor: "hsla(210, 98%, 48%, 0.5)",
    outlineOffset: "2px",
  },
}));

const SyledCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  gap: 4,
  padding: 16,
  flexGrow: 1,
  "&:last-child": {
    paddingBottom: 16,
  },
});

const StyledTypography = styled(Typography)({
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  textOverflow: "ellipsis",
  marginBottom: 10,
});

export default function MainContent() {
  return (
    <Grid container spacing={2} columns={12}>
      {cardData.map((d, index) => (
        <Grid key={index} size={{ xs: 12, md: 4 }}>
          <SyledCard variant="outlined" tabIndex={0}>
            <NormalLink to={d.link || "#"}>
              <CardMedia
                component="img"
                alt={d.title}
                image={d.img}
                sx={{
                  borderBottom: "1px solid",
                  borderColor: "divider",
                }}
              />
            </NormalLink>
            <SyledCardContent>
              <Typography gutterBottom variant="h6" component="div">
                {d.title}
              </Typography>
              <StyledTypography
                variant="body2"
                color="text.secondary"
                gutterBottom
              >
                {d.description}
              </StyledTypography>
            </SyledCardContent>
          </SyledCard>
        </Grid>
      ))}
    </Grid>
  );
}
